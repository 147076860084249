import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import {usersSlice} from "../app/modules/ECommerce/_redux/users/usersSlice";
import {productsSlice} from "../app/modules/ECommerce/_redux/products/productsSlice";
import {remarksSlice} from "../app/modules/ECommerce/_redux/remarks/remarksSlice";
import {specificationsSlice} from "../app/modules/ECommerce/_redux/specifications/specificationsSlice";
import {categoriesSlice} from '../app/modules/ECommerce/_redux/categories/categoriesSlice'
import {tagsSlice} from "../app/modules/ECommerce/_redux/tags/tagsSlice";
import {unitsSlice} from '../app/modules/ECommerce/_redux/units/unitsSlice';
import { brandsSlice } from "../app/modules/ECommerce/_redux/brands/brandsSlice";
import { databaseSlice } from '../app/modules/ECommerce/_redux/database/databaseSlice';
import { terminalsSlice } from "../app/modules/ECommerce/_redux/terminals/terminalsSlice";
import { shelvingSlice } from '../app/modules/ECommerce/_redux/shelving/shelvingSlice';
import { bulkImportSlice } from  '../app/modules/ECommerce/_redux/bulkImport/bulkImportSlice';

export const rootReducer = combineReducers({
  auth: auth.reducer,
  users: usersSlice.reducer,
  products: productsSlice.reducer,
  categories: categoriesSlice.reducer,
  remarks: remarksSlice.reducer,
  specifications: specificationsSlice.reducer,
  tags: tagsSlice.reducer,
  units: unitsSlice.reducer,
  brands: brandsSlice.reducer,
  database: databaseSlice.reducer,
  terminals: terminalsSlice.reducer,
  shelving: shelvingSlice.reducer,
  bulkImport: bulkImportSlice.reducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
