import {createSlice} from "@reduxjs/toolkit";
import { testData } from '../../pages/shelving/Fixtures/testData';

const initialShelvingState = {
    entities: [], 
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    listError: null,
    error: null,
    dataFetched: false,
    categoriesLoading: false,
    unshelvedLoading: false,
    categoriesData: [],
    unshelvedData: [],
    unshelvedCount: null
};

export const callTypes = {
  category: 'category',
  unshelved: 'unshelved',
};

export const shelvingSlice = createSlice({
  name: "shelving",
  initialState: initialShelvingState,
  reducers: {
    catchError: (state, action) => {
        state.error = action.payload.error;
        if (action.payload.callType === callTypes.list) {
            state.listLoading = false;
        }
        else {
            state.actionsLoading = false;
        }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.category)
        state.categoriesLoading = true;
      else if (action.payload.callType === callTypes.unshelved)
        state.unshelvedLoading = true;
    },
    // findShelving data
    shelvingfetched: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.entities = action.payload;
      state.dataFetched = true;
    },
    unshelvedfetched: (state, action) => {
      state.unshelvedLoading = false;
      state.error = null;
      state.unshelvedData = action.payload.rows;
      state.unshelvedCount = action.payload.count;
    },
    categoriesFetched: (state, action) => {
      state.categoriesLoading = false;
      state.categoriesData = action.payload.rows;
    },
    shelfCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities = action.payload;
    },
    shelfDeleted: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities = action.payload;
    },
    
  }
});
