/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };
  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}

        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        {/* <li
          className={`menu-item ${getMenuItemActive("/builder", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/builder">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
            </span>
            <span className="menu-text">Layout Builder</span>
          </NavLink>
        </li> */}
        {/*end::1 Level*/}

        {/* Components */}
        {/* begin::section */}
        {/* end:: section */}

        {/* Material-UI */}
        {/*begin::1 Level*/}
        {/*end::1 Level*/}

        {/* Bootstrap */}
        {/*begin::1 Level*/}
        {/*end::1 Level*/}

        {/* Applications */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">Applications</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}

        {/* eCommerce */}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/management/products", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/management/products">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Shopping/Bag2.svg"
                )}
              />
            </span>
            <span className="menu-text">Products</span>
          </NavLink>
        </li>

        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        {JSON.parse(localStorage.getItem("user"))?.accessLevel === "administrator" && <li
          className={`menu-item ${getMenuItemActive("/management/users", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/management/users">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Add-user.svg"
                )}
              />
            </span>
            <span className="menu-text">Users</span>
          </NavLink>
        </li>}
        {/*end::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/management/categories", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/management/categories">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Flatten.svg")} />
            </span>
            <span className="menu-text">Categories</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/management/database", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/management/database">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Products Data Import</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/management/bulkImport", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/management/bulkImport">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Circle.svg")} />
            </span>
            <span className="menu-text">Import Images</span>
          </NavLink>
        </li>
        {/* <li
          className={`menu-item ${getMenuItemActive("/management/terminals", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/management/terminals">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Border.svg")} />
            </span>
            <span className="menu-text">Terminals</span>
          </NavLink>
        </li> */}
        <li
          className={`menu-item ${getMenuItemActive("/management/shelving", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/management/shelving">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Arrows.svg")} />
            </span>
            <span className="menu-text">Product Shelving</span>
          </NavLink>
        </li>
        {/* Custom */}
        {/* begin::section */}
        {/* <li className="menu-section ">
          <h4 className="menu-text">Custom</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li> */}
        {/* end:: section */}

        {/* Error Pages */}
        {/*begin::1 Level*/}
        {/*end::1 Level*/}
      </ul>

      {/* end::Menu Nav */}
    </>   
  );
}
