import {createSlice} from "@reduxjs/toolkit";

const initialDatabaseState = {
  listLoading: false,
  entities: [],
  actionsLoading: false,
  lastError: null,
  file: null,
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const databaseSlice = createSlice({
  name: "database",
  initialState: initialDatabaseState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // importDatabase
    databaseImported: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.database);
    }
  }
});
