import axios from "axios";
import Cookies from 'js-cookie';

export const http = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 30000,
    withCredentials: true,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
});

http.interceptors.response.use((res) => {
  return res;
}, (error) => {
  if(error.response.status == 401 || error.response.status == 403) { 
    Cookies.set('session-expired', 'true')
    Cookies.remove('auth_token');
    localStorage.removeItem('user');
    window.location.assign("/logout");
  }
  return Promise.reject(error);
})

// Change request data/error here
http.interceptors.request.use(
  (config) => {
    const authToken = Cookies.get('auth_token');
    const user = JSON.parse(localStorage.getItem('user'));
    if(window.location.pathname === '/auth/login') {
      return config
    }
    if (!authToken && !user) {
      window.location.assign("/logout");
      throw new Error("You are not authorized to access this page");
    }
    return config;
  },
  (error) => {
    window.location.assign("/logout");
    return Promise.reject(error);
  }
);