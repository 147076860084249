export const APIEndpoints = {
    LOGIN: "api/login",
    LOGOUT: "api/logout",
    ADD_USER : "api/admin/user/create/",
    DELETE_USER : "api/admin/user/delete/",
    UPDATE_USER : "api/admin/user",
    GET_USERS: "api/users",
    GET_USER: "api/user",
    ADD_CATEGIRY: "api/admin/category/create/",
    CATEGORIES: "api/categories",
    GET_CATEGORIES: "api/products/categories",
    
    GET_PRODUCTS : "api/products",
    GET_PRODUCT : "api/product",
    GET_TAGS: "api/products/tags/",
    GET_TAG: "api/products/tag",
    TAGS: "api/tags",

    UNITS: "api/products/units",
    GET_CATEGORY: "api/products/category",
    BRANDS: "api/products/brands",
    GET_BRAND: "api/products/brand",
    IMPORT_DATABASE: "api/admin/importDatabase",
    GET_SHELVES: "api/products/shelves",
    SHELVING_INSERT: "api/products/shelves/insert",
    SHELVING_SWAP: "api/products/shelves/swap",
    UPDATE_SHELF_ORDER: "api/shelves/swap",
    IMPORT_IMAGES: "api/admin/importImages",
    GET_UNSHELVED_DATA: "api/products/unshelved",

    GET_TERMINALS: "api/terminals",
    GET_TERMINAL: "api/terminal",
}