import {createSlice} from "@reduxjs/toolkit";

const initialBulkImportState = {
  isLoading: false,
  lastError: null,
  images: [],
  error: null,
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const bulkImportSlice = createSlice({
  name: "bulkImport",
  initialState: initialBulkImportState,
  reducers: {
    catchError: (state, action) => {
      state.error = action.payload.error;
      state.isLoading = false;
    },
    startCall: (state, action) => {
      state.error = null;
      state.isLoading = true;
    },
    // bulk import images
    ImagesImported: (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.images.push(action.payload.images);
    }
  }
});
