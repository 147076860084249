import {createSlice} from "@reduxjs/toolkit";

const initialTerminalsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  terminalForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const terminalsSlice = createSlice({
  name: "terminals",
  initialState: initialTerminalsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getTerminalById
    terminalFetched: (state, action) => {
      state.actionsLoading = false;
      state.terminalForEdit = action.payload.terminalForEdit;
      state.error = null;
    },
    // findTerminals
    terminalsFetched: (state, action) => {
      const { totalCount, terminalData } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = terminalData;
      state.totalCount = totalCount;
    },
    // createTerminal
    terminalCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.terminal);
    },
    // updateTerminal
    terminalUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.terminal.id) {
          return action.payload.terminal;
        }
        return entity;
      });
    },
    // deleteTerminal
    terminalDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteTerminals
    terminalsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // terminalsUpdateState
    terminalsStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
