import {createSlice} from "@reduxjs/toolkit";

const initialBrandsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  brandsForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const brandsSlice = createSlice({
  name: "brands",
  initialState: initialBrandsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getBrandById
    brandFetched: (state, action) => {
      state.actionsLoading = false;
      state.brandsForEdit = action.payload.brandsForEdit;
      state.error = null;
    },
    // findBrands
    brandsFetched: (state, action) => {
      const {rows: brands, count} = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = brands;
      state.totalCount = count;
    },
    // createBrand
    brandCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.brand);
    },
    // updateBrand
    brandUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.brand.id) {
          return action.payload.brand;
        }
        return entity;
      });
    },
    // deleteBrand
    brandDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteBrands
    brandsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // brandsUpdateState
    brandsStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
