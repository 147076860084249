import {createSlice} from "@reduxjs/toolkit";

const initialUnitsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  unitForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const unitsSlice = createSlice({
  name: "units",
  initialState: initialUnitsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getUnitById
    unitFetched: (state, action) => {
      state.actionsLoading = false;
      state.unitForEdit = action.payload.unitForEdit;
      state.error = null;
    },
    // findUnits
    unitsFetched: (state, action) => {
      const {rows: units, count} = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = units;
      state.totalCount = count;
    },
    // createUnit
    unitCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.unit);
    },
    // updateUnit
    unitUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(unit => {
        if (unit.id === action.payload.unit.id) {
          return action.payload.unit;
        }
        return unit;
      });
    },
    // deleteUnit
    unitDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteUnits
    unitsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // unitsUpdateState
    unitsStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(unit => {
        if (ids.findIndex(id => id === unit.id) > -1) {
          unit.status = status;
        }
        return unit;
      });
    }
  }
});
