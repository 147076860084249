import axios from "axios";
import { APIEndpoints } from "../../../utils/APIEndpoints";
import { http } from "../../../utils/http";
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
export const ME_URL = `${process.env.REACT_APP_API_URL}auth/me`;


export const login = (username, password, lang) => {
  return http.post(`${lang}/${APIEndpoints.LOGIN}`, { username, password });
}

export const register = (email, fullname, username, password) => {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export const requestPassword = (email) => {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export const getUserByToken = () => {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}

export const logout = (lang) => {
  return http.post(`${lang}/${APIEndpoints.LOGOUT}`);
}
